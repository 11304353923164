// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60500,
  JSSThemeVariables60500,
  getJSSThemePatterns60500,
} from './JSSTheme60500';
import { GeoblockPage60500 } from './GeoblockPage60500';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop60500')).FullDesktop60500,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile60500')).FullMobile60500,
  }),
  JSSTheme: JSSTheme60500,
  JSSThemeVariables: JSSThemeVariables60500,
  JSSThemePatterns: getJSSThemePatterns60500,
  GeoblockPage: GeoblockPage60500,
});
