// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';
import {
  BACKGROUND_IMAGE_LIVE_BANNER_58_AMERICANFOOTBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_58_BADMINTON,
  BACKGROUND_IMAGE_LIVE_BANNER_58_BASEBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_58_BASKETBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_58_BEACHSOCCER,
  BACKGROUND_IMAGE_LIVE_BANNER_58_BEACHVOLLEYBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_58_BOWLS,
  BACKGROUND_IMAGE_LIVE_BANNER_58_DARTS,
  BACKGROUND_IMAGE_LIVE_BANNER_58_FIELDHOCKEY,
  BACKGROUND_IMAGE_LIVE_BANNER_58_FOOTBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_58_FORMULA1,
  BACKGROUND_IMAGE_LIVE_BANNER_58_FUTSAL,
  BACKGROUND_IMAGE_LIVE_BANNER_58_HANDBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_58_ICEHOCKEY,
  BACKGROUND_IMAGE_LIVE_BANNER_58_SNOOKER,
  BACKGROUND_IMAGE_LIVE_BANNER_58_TABLETENNIS,
  BACKGROUND_IMAGE_LIVE_BANNER_58_TENNIS,
  BACKGROUND_IMAGE_LIVE_BANNER_58_VOLLEYBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_58_ESPORTS,
  BACKGROUND_IMAGE_LIVE_BANNER_58_DEFAULT,
} from './constants60500';

export const JSSThemeCoupons60500: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    colors: {
      colorTypes: { background1, text4, primary },
    },
    margins,
  } = themeVars;
  return {
    MobileWidgetCouponView: {
      sportMobileWrap: {
        background: background1,
      },
    },
    SportTabs: {
      tabs: {
        backgroundColor: background1,
      },
    },
    AllLeagues: {
      root: { backgroundColor: background1 },
    },
    SuspendedBlock: {
      suspendedBlock: {
        background: 'rgba(255, 255, 255, 0.2)',
        color: '#fff',
      },
    },
    Market: {
      root: {
        padding: [margins.xs, 0],
        borderRadius: 0,
        borderTop: 'none',
        background: background1,
      },
      date: {
        color: text4,
      },
    },
    WidgetSport: {
      event: {
        margin: [margins.md, 0],
        background: background1,
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        color: primary,
        fontWeight: 'bold',
        '&:hover': {
          color: '#fff',
        },
      },
    },
    EventCoupon: {
      eventHeader: {
        backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_DEFAULT,
        '&.sport-1': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_AMERICANFOOTBALL,
        },
        '&.sport-5000': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_BADMINTON,
        },
        '&.sport-226': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_BASEBALL,
        },
        '&.sport-227': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_BASKETBALL,
        },
        '&.sport-1500': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_BEACHSOCCER,
        },
        '&.sport-1250': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_BEACHVOLLEYBALL,
        },
        '&.sport-3400': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_BOWLS,
        },
        '&.sport-22886': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_DARTS,
        },
        '&.sport-3500': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_FIELDHOCKEY,
        },
        '&.sport-240': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_FOOTBALL,
        },
        '&.sport-1300': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_FORMULA1,
        },
        '&.sport-1600': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_FUTSAL,
        },
        '&.sport-1100': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_HANDBALL,
        },
        '&.sport-228, &.sport-2100': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_ICEHOCKEY,
        },
        '&.sport-22884': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_SNOOKER,
        },
        '&.sport-1900': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_TABLETENNIS,
        },
        '&.sport-239': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_TENNIS,
        },
        '&.sport-1200': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_VOLLEYBALL,
        },
        '&.sport-6000': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_58_ESPORTS,
        },
      },
    },
    Betbuilder: {
      toggler: {
        '&:hover': {
          background: primary,
        },
      },
      exampleContainer: {
        background: '#0a1724',
      },
    },
  };
};
