// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems60500: JSSThemeDefaultBannerItemsType = themeVars => {
  const {
    colors: {
      colorTypes: { white, text2, border2, border1 },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    BannerContent: {
      bannerContentRoot: {
        color: white,
        padding: margins.xm,
      },
      bannerContentFill: {
        padding: '0',
      },
      headerCasinoCarousel: {
        textShadow: '5px 5px 5px rgba(0,0,0,0.5)',
      },
      bannerContentCasinoCarousel: {
        maxWidth: 500,
      },
      bannerContentSportsbookCarousel: {
        padding: margins.xm,
        justifyContent: 'space-between',
      },
      bannerContentSportsbookSide: {
        padding: [margins.xl, margins.lg],
      },
      bannerContentCasinoSide: {
        padding: [margins.xl, margins.lg],
      },
      bannerContentPromo: {
        padding: [margins.md],
      },
      headerSportsbookCarousel: {
        fontSize: [fontSizes.xl],
        fontWeight: 'normal',
      },
      headerPromo: {
        fontSize: '26px',
        lineHeight: 'normal',
        textShadow: 'none',
        fontWeight: 'bold',
        maxWidth: 500,
      },
      descriptionSportsbookCarousel: {
        fontSize: fontSizes.sm,
        color: text2,
      },
      descriptionCasinoCarousel: {
        fontSize: [fontSizes.lg],
        color: text2,
        padding: [margins.xs, margins.md],
      },
      descriptionSportsbookSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionCasinoSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionPromo: {
        fontSize: fontSizes.lg,
        lineHeight: '20px',
        padding: '0',
      },
      textWrapperFill: {
        padding: '0',
      },
      buttonsWrapperSportsbookCarousel: {
        margin: [margins.md, 0, 0, 0],
      },
      buttonsWrapperSportsbookSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperCasinoSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperPromo: {
        margin: [margins.lg, 0, 0, 0],
      },
    },
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        padding: [0, margins.xs],
        marginRight: '0',
      },
      imgContainer: {
        height: '120px',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right',
      },
      contentWrapper: {
        width: 'auto',
        padding: '0',
        backgroundColor: border2,
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: border2,
      },
      descriptionTimeWrapper: {
        color: text2,
      },
      linkWrapper: {
        margin: [margins.md, 0],
      },
      linkEvent: {
        color: border1,
      },
    },
    SideBanner: {
      containerWithContent: {
        backgroundPosition: 'left center',
        padding: '0',
      },
    },
    BannerItemCasinoCarousel: {
      mobile: {
        height: '200px',
        '&:after': {
          background: `linear-gradient(
            to right,
            rgba(10, 31, 50, 1) 0%,
            rgba(10, 31, 50, 0) 10%,
            rgba(10, 31, 50, 0) 100%
          )`,
        },
      },
    },
  };
};
