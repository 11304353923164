// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip60500';
import { JSSThemeHeaderMenu60500 } from './JSSThemeHeaderMenu60500';
import { JSSThemeMyBets60500 } from './JSSThemeMyBets60500';
import { JSSThemeBannerItems60500 } from './JSSThemeBannerItems60500';
import { JSSThemeSportsMenu60500 } from './JSSThemeSportsMenu60500';
import { JSSThemeForm60500 } from './JSSThemeForm60500';
import { JSSThemeTooltip60500 } from './JSSThemeTooltip60500';
import { JSSThemeBonuses60500 } from './JSSThemeBonuses60500';
import { JSSThemeHeaderMobile60500 } from './JSSThemeHeaderMobile60500';
import { JSSThemeSearch60500 } from './JSSThemeSearch60500';
import { JSSThemeEventSlider60500 } from './JSSThemeEventSlider60500';
import { JSSThemeOutcomes60500 } from './JSSThemeOutcomes60500';
import { JSSThemeCoupons60500 } from './JSSThemeCoupons60500';
import { JSSThemeHeader60500 } from './JSSThemeHeader60500';
import { JSSThemeBalance60500 } from './JSSThemeBalance60500';
import { JSSThemeAuthForm60500 } from './JSSThemeAuthForm60500';
import { JSSThemePopularLeagues60500 } from './JSSThemePopularLeagues60500';
import { JSSThemeAccountSection60500 } from './JSSThemeAccountSection60500';
import { JSSTheme60500Resets } from './JSSTheme60500Resets';
import { JSSThemeLive60500 } from './JSSThemeLive60500';
import { JSSThemeCasino60500 } from './JSSThemeCasino60500';
import { JSSThemePromotions60500 } from './JSSThemePromotions60500';
import { JSSThemeSitemap60500 } from './JSSThemeSitemap60500';

export const colorTypes60500: ColorTypes = {
  ...colorTypes,
  primary: '#64a844',
  text1: '#494949',
  text2: '#bfbfbf',
  text3: '#203552',
  text4: 'rgba(255, 255, 255, 0.5)',
  text5: '',
  background1: '#091623',
  background2: '#1e4e07',
  background3: '#112840',
  background4: '#999',
  background5: '#2B456B',
  background6: '#2B456B', //new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#4e8235',
  success: '#21a22d',
  error: '#ff3434',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: 'orange',
  inform: '#830202',
  neutral1: '#2B456B',
  neutral2: '#5c0101',
  neutral3: '',
  neutral4: '#1f3d63', //available for a new color
  border1: '#545e66',
  border2: '#0d1f2d',
};

const pick = ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
}) => ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
});

export const colorCombinations60500 = getColorCombinations(
  colorTypes60500,
  pick
);

export const JSSThemeVariables60500: JSSThemeVariablesShapeType = {
  colors: {
    colorTypes: colorTypes60500,
    colorCombinations: colorCombinations60500,
  },
  fonts: {
    default: {
      name: 'osg-ubuntu',
      fontFamily: 'osg-ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'ubuntu/ubuntu-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'ubuntu/ubuntu-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
};

export const JSSTheme60500: JSSThemeShapeType = themeVars => {
  const {
    colors: {
      colorCombinations: {
        whiteBg1,
        whiteNeutral1,
        whiteAccent,
        whitePrimary,
        text4,
      },
      colorTypes: {
        background1,
        background3,
        primary,
        text2,
        text1,
        black,
        white,
        neutral1,
      },
    },
    fontSizes,
    margins,
    borderRadius,
  } = themeVars;
  return {
    Heading: {
      common: {
        color: text4,
        padding: [margins.md, margins.xm, '0'],
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: background1,
      },
      textGeoblock: {
        color: white,
      },
    },
    mybets: JSSThemeMyBets60500(themeVars),
    header: JSSThemeHeader60500(themeVars),
    headerMenu: JSSThemeHeaderMenu60500(themeVars),
    Button: {
      root: {
        lineHeight: '26px',
      },
      default: getButtonColors(whiteNeutral1),
      primary: getButtonColors(whiteAccent),
      secondary: getButtonColors(whiteNeutral1),
      accent: getButtonColors(whitePrimary),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkSecondary: {
        color: text2,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
          },
        }),
      },
      linkAccent: {
        color: primary,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text1,
          },
        }),
      },
    },
    betslip: betslip(themeVars),
    LiveCalendar: {
      h2: {
        ...whiteBg1,
        fontWeight: 'bold',
        padding: [0, margins.md],
      },
      dateTitle: {
        background: background3,
        borderBottom: `1px solid ${background1}`,
        padding: [margins.sm, margins.md],
      },
      dateEvent: {
        background: background1,
        borderBottom: `1px solid ${background3}`,
        padding: [margins.sm, margins.md],
        '&:hover': {
          background: background3,
        },
      },
    },
    IconCircle: {
      root: {
        background: primary,
      },
    },
    NoBetsMessage: {
      root: {
        background: background1,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: `inset 0px -1px ${black}`,
        background: 'transparent',
        color: white,
      },
      title: {
        lineHeight: `${margins.xl}px`,
        fontWeight: 'normal',
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakeTitle: {
        fontWeight: 'normal',
        marginBottom: margins.md,
        marginTop: 0,
        fontSize: fontSizes.sm,
      },
      money: {
        fontSize: fontSizes.md,
        color: white,
      },
      stakePotentialWrapper: {
        marginTop: margins.xs,
        marginBottom: margins.sm,
        background: neutral1,
        padding: [margins.xs, margins.md],
        borderTop: `none`,
        borderRadius: borderRadius.md,
      },
      stakePotential: {
        color: text2,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        ...whiteBg1,
        fontSize: fontSizes.sm,
        margin: 0,
      },
    },
    banners: JSSThemeBannerItems60500(themeVars),
    sportsMenu: JSSThemeSportsMenu60500(themeVars),
    form: JSSThemeForm60500(themeVars),
    tooltip: JSSThemeTooltip60500(themeVars),
    bonuses: JSSThemeBonuses60500(themeVars),
    headerMobile: JSSThemeHeaderMobile60500(themeVars),
    search: JSSThemeSearch60500(themeVars),
    eventSlider: JSSThemeEventSlider60500(themeVars),
    outcomes: JSSThemeOutcomes60500(themeVars),
    coupons: JSSThemeCoupons60500(themeVars),
    balance: JSSThemeBalance60500(themeVars),
    live: JSSThemeLive60500(themeVars),
    authForm: JSSThemeAuthForm60500(themeVars),
    popularLeagues: JSSThemePopularLeagues60500(themeVars),
    accountSection: JSSThemeAccountSection60500(themeVars),
    promotions: JSSThemePromotions60500(themeVars),
    sitemap: JSSThemeSitemap60500(themeVars),
    resets: JSSTheme60500Resets(themeVars),
    casino: JSSThemeCasino60500(themeVars),
    PageNotFound: {
      pageNotFoundRoot: {
        color: white,
        background:
          'url(https://cdn.tornadobet.com/cms/uploads/default/tornado-bg.png)',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
      },
    },
  };
};

export const getJSSThemePatterns60500: GetJSSThemePatternsType = themeVars => {
  const {
    colors: {
      colorCombinations: { text2Bg2, whitePrimary, whiteAccent },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whiteAccent),
      secondary: getButtonColors(text2Bg2),
      accent: getButtonColors(whitePrimary),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
