// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile60500: JSSThemeDefaultHeaderMobileType = themeVars => {
  const {
    colors: {
      colorCombinations: { whiteText3 },
      colorTypes: { text3 },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      headerContainer: {
        background: text3,
      },
      menuContainer: {
        ...whiteText3,
      },
      logoImg: {
        maxWidth: '100px',
        width: 'fit-content',
      },
    },
  };
};
