// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes60500: JSSThemeDefaultOutcomesType = themeVars => {
  const {
    colors: {
      colorTypes: { neutral1, text1, white, background5, primary },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      root: {
        background: neutral1,
      },
      wideRoot: {
        background: neutral1,
        border: `1px solid ${text1}`,
      },
      priceValue: {
        color: white,
      },
    },
    Outcome: {
      root: {
        background: background5,
        height: '28px',
        lineHeight: '28px',
        border: `1px solid rgba(255, 255, 255, 0.1)`,
        '&.active': {
          background: primary,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: primary,
          },
        },
      },
    },
  };
};
